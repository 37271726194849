<template>
  <footer>
    <div class="footer_main">
      <div class="footer_1 flex ac jb">
        <div class="footer_1_1">
          <p class="flex"><img :src="require('../assets/icon6.png')" />咨询热线 </p>
          <span>400-005-9850</span>
          <p class="pc">地址：湖南省长沙市开福区湘域智慧南栋7层</p>
        </div>
        <div class="footer_1_2">
          <div class="bottom_nav_main">
            <ul class="BNavlist flex ac">
              <li v-for="(item,index) in list" :key="index">
                <router-link :to='item.url' class="flex ac">
                  {{item.text}}
                  <span class="trans_form"></span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer_1_3 flex">
          <!-- <img :src="require('../assets/code1.png')" />
          <img :src="require('../assets/code2.jpg')" /> -->
        </div>
      </div>
      <div class="footer_2 ac jc wap">
        <p>地址：湖南省长沙市开福区湘域智慧南栋713、714</p>
      </div>
    </div>
    <div class="copyright_main">
      <div class="copyright_1">
        <span>国蔚科技版权所有 ICP许可证号 <a href="http://beian.miit.gov.cn" target="_blank" rel="nofollow">琼ICP备2024047532号-1</a></span>
        <span>国蔚科技有限公司</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      list:[
        { text: '首页', url: 'home' },
        { text: '产品和服务', url:'products', childen: true },
        { text: '解决方案', url: 'project', childen: true },
        // { text: '经典案例', url: 'case' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.footer_main {
	padding: 60px 0;
	background-color: #17181b;
}

.footer_1_1 {
  p {
    color: #fff;
    font-size: 14px;
  }
  span {
    color: #fff;
    font-size: 28px;
    // margin-top: 20px;
    display: block;
  }
  img {
    width: 16px;
    margin-right: 4px;
  }
}

.footer_1_3 img {
	margin-right: 10px;
	width: 96px;
}

.copyright_1 {
  a {
    color: #808082;
    transition: color .2s;
    &:hover {
      color: #0071ef;
    }
  }
  span {
    margin-right: 5px;
  }
}

.footer_2 {
  margin-top: 12px;
	color: #808082;
	font-size: 14px;
	text-align: center;
}

.copyright_main {
	padding: 20px 0;
	background-color: #17181b;
	border-top: 1px solid rgba(255,255,255,0.1);
	color: #808082;
	text-align: center;
	font-size: 14px;
}

.BNavlist li {
	margin-left: 28px;
	height: 63px;
	cursor: pointer;
  &:first-child {
    margin-left: 0;
  }
  &:hover a {
    color: #007aff;
  }
  a {
    color: #fff;
    font-size: 14px;
    height: 100%;
  }
}
</style>
