<template>
  <div class="banner_main">
    <el-drawer
      :visible.sync="drawer"
      direction="ltr"
      :with-header="false"
      :destroy-on-close="true"
      :show-close="false"
      size="70%"
    >
      <ul class="navlist1 flex fc">
        <li v-for="(items,index) in list" :key="index" :class="active == items.url ? 'active' : ''">
          <div class="info flex jb ac">
            <div class="ChannelName" @click="goUrl(items)">
              {{items.text}}
            </div>
            <span :class="'trans_form ' + (items.show ? 'on' : '')" v-if="items.childen" @click="onshow(index)"></span>
          </div>
          <div class="wrap" v-if="items.childen">
            <div v-for="(lists, k) in items.childenlist" :key="k" v-show="lists.show">
              <div class="item" v-for="(item, y) in lists.list" :key="y" @click="gochildenUrl(item, y)">
                {{item.channelname}}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </el-drawer>
    <div class="logo_main">
      <div class="logo_1 flex jb ac">
        <div class="logo">
          <router-link to="/home">
            <img :src="logo" />
          </router-link>
        </div>
        <div class="nav_main pc">
          <ul class="navlist flex ac">
            <li v-for="(items,index) in list" :key="index" :class="active==items.url?'flex ac on':'flex ac'">
              <router-link :to='items.url' class="channelname flex ac">{{items.text}}</router-link>
              <span class="trans_form" v-if="items.childen"></span>
              <div class="navchild_main" v-if="items.childen">
                <div class="navchild" v-for="(lists,k) in items.childenlist" :key="k" >
                  <div class="title" v-if="lists.text">
                    <span>{{lists.text}}</span>
                  </div>
                  <div class="items">
                    <div class="item" v-for="(item,k) in lists.list" :key="k" >
                      <router-link :to="{ path: item.url,query: { id: k}}" class='flex ac'>
                        <div class="left_info" v-if="item.icon">
                          <img :src="item.icon">
                        </div>
                        <div class="right_info">
                          <span>{{item.channelname}}</span>
                          <p  v-if="item.title">{{item.title}}</p>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="title_name wap">国蔚科技有限公司</div>
        <div class="menu wap" @click="drawer = true">
          <img :src="require('../assets/menu.png')" />
        </div>
        <!-- <div class="logo_right flex ac">
          <span class="flex ac jc" @click="openNewWindow(1)">资管系统</span>
          <span class="flex ac jc" @click="openNewWindow(2)">工单系统</span>
        </div> -->
      </div>
    </div>
    <div class="banner" v-if="active == 'home'">
      <div class="banner_1 pc">
        <Swiper class="box" :options="options" ref="swiper">
          <SwiperSlide v-for="(item, i) in banner" :key="i">
            <img :src="item" />
          </SwiperSlide>
          <!-- <div slot="button-prev" class="swiper-button-prev"></div>
          <div slot="button-next" class="swiper-button-next"></div>
          <div slot="pagination" class="swiper-pagination"></div> -->
        </Swiper>
      </div>
      <div class="banner_1 wap">
        <Swiper class="box" :options="options" ref="swiper">
          <SwiperSlide v-for="(item, i) in bannerwap" :key="i">
            <img :src="item" />
          </SwiperSlide>
          <!-- <div slot="button-prev" class="swiper-button-prev"></div>
            <div slot="button-next" class="swiper-button-next"></div>
            <div slot="pagination" class="swiper-pagination"></div> -->
        </Swiper>  
      </div>
    </div>
    <div class="banner" v-else>
      <!-- <img :src="require('../assets/'+active+'/pc.png')" class="pc"/>
      <img :src="require('../assets/'+active+'/wap.png')" class="wap"/> -->
      <img src="../assets/banner.jpg" class="pc"/>
      <img src="../assets//banner_wap.jpg" class="wap"/>
    </div>  
  </div>
</template>
<script>
import Vue from 'vue'
import { Drawer } from 'element-ui';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

Vue.use(Drawer)
export default {
  components: { Swiper, SwiperSlide },
  data() {
    return {
      drawer: false,
      logo: require('../assets/logo.png'),
      banner:[
        require('../assets/banner.jpg')
      ],
      bannerwap:[
        require('../assets/banner_wap.jpg')
      ],
      active: 'home',
      list: [
        {
          text: '首页',
          url: 'home',
          childen: false,
          pcimg: require('../assets/banner.jpg'),
          wapimg: require('../assets/banner_wap.jpg'),
        },
        {
          text: '产品和服务',
          url: 'products',
          childen: true,
          pcimg: require('../assets/banner.jpg'),
          wapimg: require('../assets/banner_wap.jpg'),
          childenlist: [{
            text:' 产品',
            show: false,
            list: [
              {icon:require('../assets/icon_2_1.png'),channelname:'智能监控平台',channeltitle:'智能',title:'及时、准确、智能，全面掌握应用和资源健康状态！',url:'products'},
              {icon:require('../assets/icon_2_2.png'),channelname:'IT运维管理',channeltitle:'',title:'对网络设备、操作系统运行状况进行监控和管理',url:'products'},
              // {icon:require('../assets/icon_2_3.png'),channelname:'二手机交易平台',channeltitle:'公私域',title:'针对工程机械二手市场打造的SaaS产品',url:'products'},
              {icon:require('../assets/icon_2_4.png'),channelname:'OA系统',channeltitle:'公私域',title:'泛微数字化运营管理平台',url:'products'},
              {icon:require('../assets/icon_2_5.png'),channelname:'CRM系统',channeltitle:'',title:'客户信息、销售流程全方位管理',url:'products'},
              // {icon:require('../assets/icon_2_6.png'),channelname:'二手工程机械金融',channeltitle:'库融',title:'二手工程机械金融SaaS服务',url:'products'},
              {icon:require('../assets/icon_2_7.png'),channelname:'工单管理平台',channeltitle:'',title:'一张工单串联整个业务流程，全程可追溯',url:'products'},
              {icon:require('../assets/icon_2_8.png'),channelname:'物资管理平台',channeltitle:'全链条',title:'让企业实现资产全生命周期智能化管理',url:'products'},      
            ]
          }]
        },
        {
          text: '解决方案',
          url: 'project',
          childen: true,
          childenlist: [{
            text: '方案',
            show: false,
            list:[
              {
                channelname: '国蔚智能监控', url: 'project',
                link: [
                  { icon: require('../assets/icon_3_2.png'), text: '设备管理 ' },
                  { icon: require('../assets/icon_3_3.png'), text: '模板配置' },
                  { icon: require('../assets/icon_3_4.png'), text: '告警管理' },
                  { icon: require('../assets/icon_3_5.png'), text: '网络拓扑图' },
                  { icon: require('../assets/icon_3_6.png'), text: '数据分析' }
                ]
              },
              {
                channelname: 'IT运维管理', url: 'project',
                link:[
                  {icon:require('../assets/icon_3_2.png'),text:'人员调度与管理'},
                  {icon:require('../assets/icon_3_3.png'),text:'工作内容管理'},
                  {icon:require('../assets/icon_3_4.png'),text:'数据分析与优化'},
                ]
              },
              {
                channelname: '物资管理平台', url:  'project',
                link:[
                  { icon:require('../assets/icon_3_1.png'),text:'供应商管理'},
                  { icon:require('../assets/icon_3_2.png'),text:'物资转移'},
                  { icon:require('../assets/icon_3_3.png'),text:'物资清单'},
                  { icon:require('../assets/icon_3_4.png'),text:'库存盘点'},
                  { icon:require('../assets/icon_3_5.png'),text:'库存报表'},
                  { icon:require('../assets/icon_3_6.png'),text:'库存查询与报警'},
                ]
              },
              {
                channelname: 'OA管理平台', url: 'project',
                link:[
                  { icon: require('../assets/icon_3_1.png'), text: '工作门户' },
                  { icon: require('../assets/icon_3_2.png'), text: '流程审批' },
                  { icon: require('../assets/icon_3_3.png'), text: '知识文档' },
                  { icon: require('../assets/icon_3_4.png'), text: '电子签章' },
                  { icon: require('../assets/icon_3_5.png'), text: '移动办公' },
                ]
              },
              {
                channelname: 'CRM管理平台',url: 'project',
                link:[
                  { icon: require('../assets/icon_3_2.png'), text: '客户管理' },
                  { icon: require('../assets/icon_3_3.png'), text: '企业管理' },
                  { icon: require('../assets/icon_3_4.png'), text: '商机管理' },
                  { icon: require('../assets/icon_3_5.png'), text: '市场分析' },
                ]
              },
              {
                channelname: '工单管理平台',url: 'project',
                link:[
                  { icon: require('../assets/icon_3_1.png'), text: '大屏展示' },
                  { icon: require('../assets/icon_3_2.png'), text: '我的报修单' },
                  { icon: require('../assets/icon_3_3.png'), text: '我的工单' },
                  { icon: require('../assets/icon_3_4.png'), text: '工单预约' },
                  { icon: require('../assets/icon_3_5.png'), text: '超时提醒' },
                  { icon: require('../assets/icon_3_6.png'), text: '维修抢单' },
                ]
              },
              // {
              //   channelname: 'crm信息平台', url: 'project',
              //   link:[
              //     { icon: require('../assets/icon_3_2.png'), text: '机械损坏保险' },
              //     { icon: require('../assets/icon_3_3.png'), text: '第三者责任保险' },
              //     { icon: require('../assets/icon_3_4.png'), text: '全额保险' }
              //   ]
              // }
            ]
          }]
        },
        // { text:'经典案例', url:'case', childen: false }
      ],
      options: {
        //导航前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        //自动轮播图
        autoplay: false,
        //无缝衔接
        loop: true,
        //分页器配置项
        pagination: {
          el: ".swiper-pagination",
          clickable: true // 点击分页器小球进行轮播图切换
        }
      }
    }
  },
  created() {
    this.active = this.$route.name
  },
  methods: {
    openNewWindow(index){
      let obj = ''
      if (index === 1) {
        obj = 'http://am.guoweisoft.com/ledger'
      } else if (index === 2) {
        obj = 'http://oa.guoweisoft.com'
      }
      window.open(obj, '_blank')
    },
    goUrl(data){
      this.drawer = false
      const path = this.$route.path
      if (path !== '/' + data.url) {
        this.$router.push({ path: data.url })
      }
    },
    gochildenUrl(data, index){
      this.drawer = false
      const path = this.$route.path
      const query = this.$route.query || {}
      if (path !== '/' + data.url) {
        console.log(path)
        this.$router.push({ path: data.url, query: { id: index } })
      } else if (query.id != undefined && +query.id !== index) {
        console.log(query)
        this.$router.push({ path: data.url, query: { id:index } })
      }
    },
    onshow(index){
      var show = ''
      if (this.list[index].childenlist[0].show) {
        show = false
      } else {
        show = true
      }
      this.list.forEach(items => {
        if (items.childen) {
          items.childenlist.forEach(lists => {
            this.$set(lists, 'show', false)
          })
        }
      })
      this.$set(this.list[index].childenlist[0], 'show', show)
    }
  }
}
</script>

<style lang="less" scoped>
.banner_main {
	position: relative;
}

.logo_main {
	position: fixed;
  left: 0;
  top: 0;
	width: 100%;
	// background-color: transparent;
	background: #fff;
	border-bottom: 1px solid rgba(0, 11, 23, 0.04);
	z-index: 999;
}

// .logo_main:hover {
// 	background: #fff;
// }

.logo img {
	width: 79px;
}

.nav_main {
	flex: 1 1 0%;
	margin-left: 40px;
}

.navlist li {
	margin-right: 28px;
	height: 63px;
	cursor: pointer;
	position: relative;
  &:hover {
    .trans_form {
      background: url('../assets/arrow2.png') no-repeat center;
    }
    .channelname {
      color: #007aff;
    }
    .navchild_main {
      visibility: visible;
      opacity: 1;
      transform: translateY(64px);
      transition-duration: 150ms;
    }
  }
  &.on .channelname {
    color: #007aff;
  }
  .channelname {
    color: rgb(23, 24, 27);
    font-size: 14px;
    height: 100%;
  }
  .trans_form {
    position: relative;
    width: 14px;
    height: 14px;
    background: url('../assets/arrow1.png') no-repeat center;
  }
}

.navchild_main {
	box-shadow: rgba(0, 33, 71, 0.07) 0px 12px 48px 0px;
	transition-property: transform, opacity;
	transition-timing-function: cubic-bezier(0.48, 0.33, 0.24, 0.95);
	transition-duration: 0ms;
	transition-delay: 0ms;
	visibility: hidden;
	opacity: 0;
	z-index: 10;
	transform: translateY(43px);
	left: -14px;
	border: 1px solid rgba(0,11,23,.04);
	position: absolute;
	top: 0;
	padding: 32px;
	background: #fff;
  .navchild .title {
    padding-bottom: 12px;
    font-size: 14px;
    color: #333;
    border-bottom: 1px solid rgba(0, 11, 23, 0.04);
    line-height: 1;
    margin-bottom: 12px;
  }
}

.navchild_main .navchild .items .item {
	padding: 12px 0;
  &:hover span {
    color: #0071ef;
  }
  .left_info {
    width: 34px;
    margin-right: 12px;
  }
  .right_info {
    span {
      font-size: 14px;
      color: #333;
      line-height: 1;
      white-space: nowrap;
      display: block;
    }
    p {
      font-size: 12px;
      color: #808082;
      line-height: 1;
      margin-top: 8px;
      white-space: nowrap;
      display: block;
    }
  }
}

.logo_1 .menu img {
	width: 25px;
}

.logo_right span {
	background: #0071ef;
	width: 104px;
	height: 40px;
	border-radius: 2px;
	margin-left: 24px;
	color: #fff;
	font-size: 14px;
	cursor: pointer;
}

.swiper-container .swiper-pagination {
	bottom: 80px;
}

.swiper-pagination {
  .swiper-pagination-bullet {
    width: 40px;
    height: 5px;
    border-radius: 0;
    background: #fff;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: #0071ef;
  }
}

.navlist1 li {
	text-align: left;
	padding: 0  0 0 15px;
  .ChannelName {
    padding: 15px 0;
    width: 90%;
  }
  .item {
    padding: 10px 0 10px 20px;
  }
  .trans_form {
    position: relative;
    width: 60px;
    height: 14px;
    background: url('../assets/arrow1.png') no-repeat center;
    display: block;
  }
}
.navlist1 li .trans_form {
  background: url('../assets/arrow1.png') no-repeat center;
}
.banner img {
	width: 100%;
}

.banner_1 img {
	width: 100%;
}
.title_name {
  font-size: 16px;
}
</style>
