<template>
  <div id="app" class="main">
    <headerItem />
      <router-view/>
    <footerItem />
  </div>
</template>

<script>
import headerItem from '@/components/header'
import footerItem from '@/components/footer'

export default {
  name: 'App',
  components: {
    headerItem,
    footerItem
  }
}
</script>

<style lang="scss" scoped>
.main {
	background: #F8F8F8;
}
</style>
